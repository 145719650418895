import { GreyBackground, MuseumQualityBadge, TopQualityBadge, TwentyYearsBadge } from "../../../assets/Assets"

import '../../../styles/mainSkillsList.scss'
import '../../../styles/custom.scss'
import '../../../styles/elements.scss'

export default function SkillsList() {
    
    return (
        <div className='skills-list' style={{backgroundImage: `url(${GreyBackground})`}}>
            <div className="content col-c-c">
                <h1>SPECIALIZING IN SHOW QUALITY, VINTAGE TRACTOR RESTORATION</h1>
                <div className="divider-white" />
                <div className='badges'>
                    <div className="badge">
                        <img src={TwentyYearsBadge} alt="badge" />
                        <p>TWENTY YEARS EXPERIENCE</p>
                    </div>
                    <div className="badge">
                        <img src={MuseumQualityBadge} alt="badge" />
                        <p>MUSEUM QUALITY WORKSMANSHIP</p>
                    </div>
                    <div className="badge">
                        <img src={TopQualityBadge} alt="badge" />
                        <p>TOP QUALITY MATERIALS</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { 
    Case_01_01, Case_01_02, Case_01_03, Case_01_04, Case_01_05, Case_01_06, Case_01_07, Case_01_08,
    Case_02_01, Case_02_02, Case_02_03, Case_02_04, Case_02_05, Case_02_06, Case_02_07, Case_02_08,
    Case_03_01, Case_03_02, Case_03_03, Case_03_04, Case_03_05, Case_03_06, Case_03_07, Case_03_08,
    JD_03_01, JD_03_02, JD_03_03, JD_03_04, JD_03_05, JD_03_06, JD_03_07, JD_03_08,
    JD_4010_01, JD_4010_02, JD_4010_03, JD_4010_04, JD_4010_05, JD_4010_06, JD_4010_07, JD_4010_08,
    JD_4620_01, JD_4620_02, JD_4620_03, JD_4620_04, JD_4620_05, JD_4620_06, JD_4620_07, JD_4620_08
} from '../../assets/Assets'

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    projectsList: [
        {
            id: 0,
            year: "1972",
            model: "JOHN DEERE 4620",
            caption: [
                "Manufactured 1971-72.", 
                "135 hp turbo charged 6 cylinder diesel engine.",
                "Restored in 2018.",
                "Originally from Texas and was originally a cab tractor.",
                "Scanlan Restoration converted it to a open station and it went through a total tear down paint and detailed.",
            ],
            imgs: [JD_4620_01, JD_4620_02, JD_4620_03, JD_4620_04, JD_4620_05, JD_4620_06, JD_4620_07, JD_4620_08],
        },
        {
            id: 1,
            year: "1952",
            model: "D CASE",
            caption: [
                "Manufactured 1939-1953.",
                "35 hp 4 cylinder gasoline engine.",
                "This tractor went through a compete nut and bolt restoration.",
                "Engine, transmission and steering were gone through and everything that could be replaced, was with new parts.",
                "It was finished with a show quality paint and detail.",
            ],
            imgs: [Case_01_01, Case_01_02, Case_01_03, Case_01_04, Case_01_05, Case_01_06, Case_01_07, Case_01_08],
        },
        {
            id: 2,
            year: "1963",
            model: "JOHN DEERE 4010",
            caption: [
                "Manufactured 1960-63.",
                "88 hp 6 cylinder diesel engine.",
                "Prior to arrival at Scanlan Restoration this 4010 went through a complete nut and bolt restoration by the owner.",
                "It was then delivered to Scanlan restoration in pieces where it was sandblasted, under went body work, primed and painted.",
                "The final pieces were assembled back together by the owner.",
            ],
            imgs: [JD_4010_01, JD_4010_02, JD_4010_03, JD_4010_04, JD_4010_05, JD_4010_06, JD_4010_07, JD_4010_08],
        },
        {
            id: 3,
            year: "1936",
            model: "C CASE",
            caption: [
                "27hp 4 cylinder gasoline engine.",
                "This 1936 CASE is Ken’s personal tractor. He was told it had spent most of its life in a saw mill. It  was purchased by his fathers friend in Sarnia, Ontario Canada. It was brought back to Straffordville, Ontario which is where Ken’s dad bought it in 1984 ( Ken was 4 years old ). He remembers the day his dad brought it home. It was the first tractor he could remember using steel wheels and he claimed it before it was unloaded off the trailer. It’s been hauled to tractor shows and plowing matches all over Ontario. In 2006 Ken restored it so that it could be part of his wedding photos. It was a year long restoration process making sure everything was done to Ken’s very high standards.",
            ],
            imgs: [Case_03_01, Case_03_02, Case_03_03, Case_03_04, Case_03_05, Case_03_06, Case_03_07, Case_03_08],
        },
        
        {
            id: 4,
            year: "1935",
            model: "JOHN DEERE B",
            caption: [
                "16hp 2 cylinder gasoline engine.",
                "This John Deere B was restored in 2022.",
                "Scanlan Restoration began by first fixing oil leaks, tightening the steering and handles along with a tune up.",
                "Was then torn down and given a show quality paint and detailing.",
            ],
            imgs: [JD_03_01, JD_03_02, JD_03_03, JD_03_04, JD_03_05, JD_03_06, JD_03_07, JD_03_08],
        },
        {
            id: 5,
            year: "1954",
            model: "CASE 500",
            caption: [
                "Manufactured 1953-56.",
                "55 hp 6 cylinder diesel engine.",
                "Restored in 2021.",
                "Came to Scanlan Restoration from western Canada.",
                "Went through a complete tear down and given a show quality paint and detailing.",
            ],
            imgs: [Case_02_01, Case_02_02, Case_02_03, Case_02_04, Case_02_05, Case_02_06, Case_02_07, Case_02_08],
        },
    ],
}

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        reset: (state) => {
            state = initialState
        },
    }
})

export const { 
    reset, 
} = projectsSlice.actions

export default projectsSlice.reducer
import Header from './components/Header'
import SkillsList from './components/SkillsList'
import Projects from './components/Projects'
import About from './components/About'
import Contact from './components/Contact'

import '../../styles/main.scss'

export default function Main() {
    
    return (
        <div className="main">
            <Header />
            <SkillsList />
            <Projects />
            <About />
            <Contact />
        </div>
    )
}
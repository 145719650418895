import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Main from './pages/main/Main'
import Project from './pages/project/Project'

import './App.css'

export default function App() {

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<Main />}/>
          <Route path={'/project/:id'} element={<Project />}/>
        </Routes>
      </BrowserRouter>
    </div>
  )
}
import { GreyBackground } from '../../../assets/Assets'

import '../../../styles/mainAbout.scss'
import '../../../styles/custom.scss'
import '../../../styles/elements.scss'

export default function About() {
 
    return (
        <div className="about" style={{backgroundImage: `url(${GreyBackground})`}}>
            <div className="content-container">
                <div className="content col-c-c">
                    <h1>ABOUT US</h1>
                    <div className="divider-black" />
                    <p>Scanlan Restoration was created with the goal of providing a high quality restoration service with a detailed and no corners cut approach. This means that only the highest quality/grade paint and materials are always used so that your investment is completed with museum quality detail. Being a collector himself, owner Ken Scanlan was raised in the industry and understands the pride and sentiment of every project. He grew up helping his father restore tractors and this created a life long passion for Scanlan as well as a keen eye for detail.  He possesses more than 20 years technical and mechanical expertise/experience which translates into top quality machining and paint detail for your special project. Every Scanlan Restoration initiative is treated as if it was one of his own and ensures that your investment will stand out as one of the best.</p>
                </div>
            </div>
        </div>
    )
}
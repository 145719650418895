import { BlackBackground } from '../../../assets/Assets'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import '../../../styles/mainProjects.scss'
import '../../../styles/custom.scss'
import '../../../styles/elements.scss'

export default function Projects() {

    const navigate = useNavigate()
    const { projectsList } = useSelector((state) => state.projects)
    
    return (
        <div className='projects' style={{backgroundImage: `url(${BlackBackground})`}}>
            <div className="content col-c-c">
                <h1>OUR WORK</h1>
                <div className="divider-white" />
                <p>A collection of vintage tractors we've renovated with passion</p>
                <div className="projects-list grid-3col-1row">
                    {projectsList.map(project => (
                        <div 
                            key={project['id']} 
                            className="project"
                            onClick={() => {
                                navigate(`project/${project['id']}`)
                            }}
                        >
                            <img src={project['imgs'][0]} alt="thumbnail" />
                            <div className="text">
                                <p className='bold'>{project[ 'year'] + " " + project['model']}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
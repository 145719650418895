import { ChevronLeft, ChevronRight } from '../../../assets/Assets'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import '../../../styles/projectDetails.scss'
import '../../../styles/custom.scss'

export default function Details({ id }) {
    
    const { projectsList } = useSelector((state) => state.projects)
    const project = projectsList.filter(p => p['id'] === id)[0]
    const navigate = useNavigate()

    const [selectedImg, setSelectedImage] = useState(project['imgs'][0])

    const toggleSelect = (dir) => {
        const index = project['imgs'].indexOf(selectedImg)
        if (dir === 'prev') {
            const newIndex = index - 1
            if (newIndex < 0) {
                setSelectedImage(project['imgs'][project['imgs'].length - 1])
            } else {
                setSelectedImage(project['imgs'][newIndex])
            }
        } else if (dir === 'next') {
            const newIndex = index + 1
            if (newIndex > project['imgs'].length - 1) {
                setSelectedImage(project['imgs'][0])
            } else {
                setSelectedImage(project['imgs'][newIndex])
            }
        }
    }

    return (
        <div className='details'>
            <div className="content">
                <div className="title row-sb-c">
                    <span>
                        <h1 className='bold'>{project['year']} {project['model']}</h1>
                    </span>
                    <button 
                        className='btn'
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(-1)
                        }}
                    >
                        Back
                    </button>
                </div>
                <div className="body row-sa-c full-w">
                    <div className="left col-c-c">
                        <div className="image-container">
                            <img src={selectedImg} alt="displayed" />
                            <button 
                                className='prev clear'
                                onClick={(e) => {
                                    e.preventDefault()
                                    toggleSelect('prev')
                                }}
                            >
                                <img src={ChevronLeft} alt="previous" />
                            </button>
                            <button 
                                className='next clear'
                                onClick={(e) => {
                                    e.preventDefault()
                                    toggleSelect('next')
                                }}
                            >
                                <img src={ChevronRight} alt="next" />
                            </button>
                        </div>
                        <div className="images-container">
                            <div className="images">
                                {project['imgs'].map((img, i) => (
                                    <img 
                                        className={selectedImg === img ? "selected" : ""}
                                        key={i} 
                                        src={img} 
                                        alt="project" 
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setSelectedImage(img)
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="caption col">
                            {project['caption'].map((text) => (
                                <p>{text}</p>
                            ))}
                            {/* <p>{project['caption']}</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { Logo, Cover01, Cover02, Cover03, Cover04, HeaderBackground, Facebook, Twitter } from '../../../assets/Assets'

import '../../../styles/mainHeader.scss'
import '../../../styles/custom.scss'
import ImageSlider from '../../../components/ImageSlider'

export default function Header() {

    const images = [ Cover01, Cover02, Cover03, Cover04 ]

    return (        
        <div className='header full-sw-sh col'>
            <div className='banner full-w' style={{backgroundImage: `url(${HeaderBackground})`}}>
                <img className='logo' src={Logo} alt="logo" />
                <div className='socials row-sb-c'>
                    <a href="https://www.facebook.com/scanlanrestoration/" target="_blank" rel="noreferrer">
                        <img className='social-icon' src={Facebook} alt="facebook" style={{filter: 'invert(1)'}}/>
                    </a>
                    <a href="https://twitter.com/ScanlanTractor" target="_blank" rel="noreferrer">
                        <img className='social-icon' src={Twitter} alt="twitter" style={{scale: '0.8', transform: 'translate(12%, 12%)'}}/>
                    </a>
                </div>
            </div>
            <div className='slider'>
                <ImageSlider images={images}/>
            </div>
        </div>
    )
}
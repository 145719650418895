import { GreyBackground, Ken } from '../../../assets/Assets'

import { useReducer, useState } from 'react'
import { useSendContact } from '../../../hooks/customHooks'

import '../../../styles/mainContact.scss'
import '../../../styles/custom.scss'
import '../../../styles/elements.scss'

const contactReducer = (state, action) => {
    switch(action.type) {
        case "firstName":
            return {...state, firstName: action.payload}
        case "lastName":
            return {...state, lastName: action.payload}
        case "email":
            return {...state, email: action.payload}
        case "message":
            return {...state, message: action.payload}
        default:
            return
    }
}

export default function Contact() {

    const [messageDelivered, setMessageDelivered] = useState(false)
    const { loading, sendContact } = useSendContact()

    const [message, dispatchMessage] = useReducer(contactReducer, {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    })

    const submitMessage = async (e) => {
        e.preventDefault()
        const delivered = await sendContact(message)
        setMessageDelivered(delivered)
    }

    return (
        <div className='contact' style={{backgroundImage: `url(${GreyBackground})`}}>
            <img className='img' src={Ken} alt="" />
            <div className="content-container">
            <form onSubmit={(e) => submitMessage(e)}>
                <div className="content col-c-c">
                    <h1>FIND US</h1>
                    <div className="divider-black" />
                        <div className="cols grid-2col-1row">
                            <div className="left col">
                                <p className="bold">SCANLAN TRACTOR & AUTO RESTORATION</p>
                                <p>T. 519.983.3393</p>
                                <span>
                                    <p>163368 Brownsville Rd. </p>
                                    <p>Brownsville, ON</p>
                                    <p>N0L 1C0</p>
                                </span>
                            </div>
                            {!messageDelivered && (
                                <div className="right col">
                                    <span>
                                        <p>First Name</p>
                                        <input 
                                            type="text"
                                            required
                                            disabled={loading}
                                            value={message['firstName']}
                                            onChange={(e) => {
                                                dispatchMessage({type: "firstName", payload: e.target.value})
                                            }}
                                        />
                                    </span>
                                    <span>
                                        <p>Last Name</p>
                                        <input 
                                            type="text" 
                                            required
                                            disabled={loading}
                                            value={message['lastName']}
                                            onChange={(e) => {
                                                dispatchMessage({type: "lastName", payload: e.target.value})
                                            }}
                                        />
                                    </span>
                                    <span>
                                        <p>Email</p>
                                        <input 
                                            type="text" 
                                            required
                                            disabled={loading}
                                            value={message['email']}
                                            onChange={(e) => {
                                                dispatchMessage({type: "email", payload: e.target.value})
                                            }}
                                        />
                                    </span>
                                    <span>
                                        <p>Message</p>
                                        <textarea 
                                            required
                                            disabled={loading}
                                            value={message['message']}
                                            onChange={(e) => {
                                                dispatchMessage({type: "message", payload: e.target.value})
                                            }}
                                        />
                                    </span>
                                </div>
                            )}
                            {messageDelivered && (
                                <div className="right col">
                                    <p>Your message has been delivered.</p>
                                    <p>You can expect an email from us shortly.</p>
                                </div>
                            )}
                        </div>
                        {!messageDelivered && (<button className='btn'>Submit</button>)}
                    </div>
                </form>
            </div>
        </div>
    )
}
import axios from 'axios'

import { useState } from "react"

export const useSendContact = () => {

    const [loading, setLoading] = useState(false)

    const sendContact = async (message) => {

        setLoading(true)

        const name = `${message.firstName} ${message.lastName}`
        const email = message.email
        const body = message.message
        const url = `https://maker.ifttt.com/trigger/contact-form-request/with/key/lXMg1J2-VK36URPKhi-GpypYjc8DEI7tIFaYyPf0_yb?value1=${name}&value2=${email}&value3=${body}`

        try {
            await axios.post(url)
        } catch(e) {
            console.log(e)
        }

        setLoading(false)

        return true

    }

    return { loading, sendContact }

}
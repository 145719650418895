import { GreyBackground } from '../../../assets/Assets'

import '../../../styles/projectFooter.scss'
import '../../../styles/custom.scss'

export default function Footer() {

    return (
        <div className='footer' style={{backgroundImage: `url(${GreyBackground})`}}>
            <div className="content">
                <p className='bold'>SCANLAN TRACTOR & AUTO RESTORATION</p>
                <p>T. 519.983.3393</p>
                <p>163368 Brownsville Rd. Brownsville, ON N0L 1C0</p>
            </div>
        </div>
    )
}
import { ChevronLeft, ChevronRight } from "../assets/Assets"

import { useEffect, useReducer } from "react"

import '../styles/imageSlider.scss'

const dataActions = {
    TRANSITION_PREV: 'transition-prev',
    TRANSITION_NEXT: 'transition-next',
    PREV: 'prev',
    NEXT: 'next',
}

const dataReducer = (state, action) => {
    switch(action.type) {
        case dataActions.TRANSITION_PREV:
            return { ...state, class: 'img-slide-prev', disabled: true }
        case dataActions.TRANSITION_NEXT:
            return { ...state, class: 'img-slide-next', disabled: true }
        case dataActions.PREV:
            const indexsPrev = [state.prev, state.current, state.next]
            for (let i=0; i < indexsPrev.length; i++) {
                if (indexsPrev[i] === 0) {
                    indexsPrev[i] = action.payload-1
                } else {
                    indexsPrev[i] = indexsPrev[i]-1 
                }
            }
            return {prev: indexsPrev[0], current: indexsPrev[1], next: indexsPrev[2], class: '', disabled: false}
        case dataActions.NEXT:
            const indexsNext = [state.prev, state.current, state.next]
            for (let i=0; i < indexsNext.length; i++) {
                if (indexsNext[i] === action.payload-1) {
                    indexsNext[i] = 0
                } else {
                    indexsNext[i] = indexsNext[i]+1 
                }
            }
            return {prev: indexsNext[0], current: indexsNext[1], next: indexsNext[2], class: '', disabled: false}
        default:
            return state
    }
}

export default function ImageSlider({ images }) {

    const [data, dispatch] = useReducer(dataReducer, {
        prev: images.length - 1,
        current: 0,
        next: 1,
        class: '',
        disabled: false,
    })    

    useEffect(() => {
            const timeoutID = setTimeout(() => {
                startSlide(dataActions.NEXT)
            }, 10000)

            return () => clearTimeout(timeoutID)
    }, [data])

    const startSlide = (dir) => {
        if (!data.disabled) {
            if (dir === dataActions.PREV) {
                dispatch({type: dataActions.TRANSITION_PREV})
            } else if (dir === dataActions.NEXT) {
                dispatch({type: dataActions.TRANSITION_NEXT})
            }
            
            setTimeout(() => {
                dispatch({type: dir, payload: images.length})
            }, 400)
        }
    }

    return (
        <div className="img-slider">
            <div 
                className={`img prev ${data.class}`} 
                style={{backgroundImage: `url(${images[data.prev]})`, transition: data.class === "" ? null : "all 0.4s"}}
            />
            <div 
                className={`img ${data.class}`} 
                style={{backgroundImage: `url(${images[data.current]})`, transition: data.class === "" ? null : "all 0.4s"}}
            />
            <div 
                className={`img next ${data.class}`} 
                style={{backgroundImage: `url(${images[data.next]})`, transition: data.class === "" ? null : "all 0.4s"}}
            />
            <div className="arrows row-sb-c">
                <img 
                        className="arrow" 
                        src={ChevronLeft} 
                        alt="prev" 
                        onClick={() => startSlide(dataActions.PREV)}
                    />
                <img 
                    className="arrow row-sb-c" 
                    src={ChevronRight} 
                    alt="next" 
                    onClick={() => startSlide(dataActions.NEXT)}
                />
            </div>
        </div>
    )
}

import Header from "./components/Header"
import Details from "./components/Details"
import Footer from "./components/Footer"

import { useParams } from "react-router-dom"

import '../../styles/project.scss'
import { useEffect } from "react"

export default function Project() {

    const { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='featured-project'>
            <Header />
            <Details 
                id={parseInt(id)}
            />
            <Footer />
        </div>
    )
}

import { useNavigate } from "react-router-dom"
import { HeaderBackground, Facebook, Logo, Twitter } from "../../../assets/Assets"

import '../../../styles/projectHeader.scss'

export default function Header() {
    const navigate = useNavigate()

    return (
        <div className='project-header full-w' style={{backgroundImage: `url(${HeaderBackground})`}}>
            <img 
                className='logo' 
                src={Logo} alt="logo" 
                onClick={(e) => {
                    e.preventDefault()
                    navigate(-1)
                }}
            />
            <div className='socials row-sb-c'>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                    <img className='social-icon' src={Facebook} alt="facebook" style={{filter: 'invert(1)'}}/>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                    <img className='social-icon' src={Twitter} alt="twitter" style={{scale: '0.8', transform: 'translate(12%, 12%)'}}/>
                </a>
            </div>
        </div>
    )
}